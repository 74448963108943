<template>
  <div class="act-zerobuy-page page">
    <HeadBar class="headbar" title="0元欢唱" @back="clearCheckPayStatus"></HeadBar>
    <p v-if="!zerobuyStatus" class="zerobuy-time">活动报名时间：<span>{{ taskTimeText }}</span>&nbsp;&nbsp; 超时无法报名参加</p>
    <div v-if="!zerobuyStatus" class="zerobuy-content">
      <div class="zerobuy-content-p">
        <p class="zerobuy-content-t1">每日欢唱打卡 会员费全免！</p>
        <p class="zerobuy-content-t2">29元购买月会员报名，每天一首歌，连续欢唱30天，全款返还~</p>
        <div class="zerobuy-content-intro">
          <ZerobuyTips :task-duration="taskTimeText" />
        </div>
      </div>
    </div>

    <ZerobuyCalendar v-if="zerobuyStatus" />

    <div class="zerobuy-content-btn" v-if="zerobuyStatus < 5">
      <span
        :class="{
          'zerobuy-content-btn-gray': !canClickBtn,
        }"
        @click="handleClickBtn"
      >
        {{ actStatusText }}
      </span>
    </div>
    <div v-if="zerobuyStatus" class="zerobuy-intro">
      <ZerobuyTips :task-duration="taskTimeText" />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import ZerobuyCalendar from './components/calendar.vue'
import ZerobuyTips from './components/tips.vue'
import useSongItem from '@/composables/useSongItem'
import useActivity from '@/composables/useActivity'
import { updateZeroBuyIsPay } from '@/service/actZeroBuy'
import { getSingsingList } from '@/service/singing'
import { parseISO, isAfter } from 'date-fns'
import { Toast } from 'vant'
import get from 'lodash/get'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'ActZeroBuy',
  components: {
    ZerobuyCalendar,
    ZerobuyTips,
  },
  setup() {
    const store = useStore()
    const unionid = computed(() => store.state.userInfo.unionid)
    const videoPlayerHistory = computed(() => store.state.videoPlayerHistory)
    const orderedSongIdMap = computed(() => store.state.orderedSongIdMap)
    const initControlPlay = computed(() => store.state.videoInitAutoPlay)
    const orderedList = computed(() => store.state.orderedList)
    const zerobuyStatus = computed(() => store.state.zerobuy.zerobuyStatus)
    const signinCalendar = computed(() => store.state.zerobuy.signinCalendar)
    const currTaskDay = computed(() => 30 - Number(store.state.zerobuy.remaining_days))
    const { orderSong } = useSongItem()
    const { showActivityZerobuyVip } = useActivity()

    // 活动可领取时间
    const taskTimeText = ref('2023年8月31日-2023年9月15日')
    const taskTime = ['2023-08-31', '2023-09-15']
    const statusMap = {
      0: '支付并报名',
      1: ['去欢唱', '已完成'],
      2: '完成任务',
      3: '欢唱失败',
      4: '已提交',
      9: '已提交',
    }
    
    const actStatusText = computed(() => {
      if (zerobuyStatus.value === 1) {
        return get(signinCalendar.value, `${currTaskDay.value - 1}.status`) === '1' ? statusMap[1][1] : statusMap[1][0]
      }
      return statusMap[zerobuyStatus.value] || ''
    })
    const canClickBtn = computed(() => ['支付并报名', '去欢唱', '完成任务'].includes(actStatusText.value))

    let firstSong = ref({})

    const getActZeroBuy = async () => {
      const currDate = new Date()
      if (isAfter(currDate, parseISO(taskTime[1])) || isAfter(parseISO(taskTime[0]), currDate)) {
        Toast('活动已过期~')
        return
      }
      // 领取任务 打开弹框
      showActivityZerobuyVip(unionid.value)
    }

    const goSing = () => {
      if(Object.keys(orderedSongIdMap.value).length && !initControlPlay.value) {
        orderSong(orderedList.value[0], {
          position: 'recovery',
          isPushOrdered: false,
        })
        return
      }
      if (!videoPlayerHistory.value.songItem.songid) {
        // 无歌曲时从常唱中自动点选一首歌曲播放
        orderSong(firstSong.value, {
          position: 'recovery',
          isPushOrdered: true,
        })
        return
      }
      orderSong(videoPlayerHistory.value.songItem, {
        position: 'recovery',
        isPushOrdered: false,
      })
    }

    const submitAct = async () => {
      store.dispatch('zerobuy/compelteZerobuyTask', unionid.value)
    }
    
    const clickStatusMap = new Map([
      ['支付并报名', getActZeroBuy],
      ['去欢唱', goSing],
      ['完成任务', submitAct],
    ])

    const handleClickBtn = () => {
      if (clickStatusMap.has(actStatusText.value)) clickStatusMap.get(actStatusText.value)()
    }

    const handleInitSingList = async () => {
      const res = await getSingsingList({
        unionid: unionid.value,
      })
      if (res.length) {
        firstSong.value = res[0]
      }
    }

    const getSigninStatus = () => {
      store.dispatch('zerobuy/updateSigninStatus', unionid.value)
    }

    const getTaskStatus = () => {
      store.dispatch('zerobuy/updateZerobuyStatus', unionid.value)
    }

    // 支付完参与活动还得调一次task_sign_order接口 这块接口设计不太好 处理一下
    const checkUserIsPay = () => {
      // 延时2s，如果未参与活动，查单更新用户参与活动得状态，避免已支付却漏掉的极限场景
      setTimeout(async () => {
        if (!zerobuyStatus.value) {
          const res = await updateZeroBuyIsPay({
            unionid: unionid.value
          })
          if (res.state) {
            setTimeout(() => {
              getTaskStatus()
            }, 1500)
          }
        }
      }, 2000)
    }

    const clearCheckPayStatus = () => {
      store.dispatch('zerobuy/clearCheckZerobuyStatus')
    }

    onMounted(async () => {
      sendLog({
        event_type: 'show',
        event_name: 1736,
        event_data: {
          str1: '1'
        }
      })
      handleInitSingList()
      getTaskStatus()
      getSigninStatus()
      checkUserIsPay()
    })

    return {
      taskTimeText,
      actStatusText,
      zerobuyStatus,
      canClickBtn,
      handleClickBtn,
      clearCheckPayStatus,
    }
  }
}
</script>

<style lang="stylus" scoped>
.act-zerobuy-page
  width 100vw
  height 100%
  padding-top 35px
  position relative
  color #000
  font-weight 400
  .zerobuy-time
    color rgba(255, 255, 255, 0.50)
    font-size 28px
    position fixed
    top 50px
    right 80px
    span
      color #F0D290
    @media screen and (max-width 1200px) and (min-height 1200px)
      position static
      margin-top 55px
      margin-bottom 50px
  .zerobuy-content
    width 1760px
    height 640px
    margin-top 55px
    border-radius 20px
    overflow hidden
    position relative
    &::before
      content ""
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      background-image linear-gradient(159deg, #585450 0%, #45413E 0.01%, #1E1F21 100%)
    &::after
      content ""
      position absolute
      right 0
      top 0
      width 880px
      height 640px
      background url('https://qncweb.ktvsky.com/20230823/vadd/ac75ff13a47ad0a6ddbf201538a86d49.png') no-repeat
      background-position center
      background-size 100% 100%
    &-p
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      padding-left 80px
      z-index 2
    &-t1
      font-size 72px
      font-weight 600
      background linear-gradient(90deg, #F0D290 0%, #F9EBC3 100%)
      background-clip text
      -webkit-background-clip text
      -webkit-text-fill-color transparent
      margin-top 80px
      height 86px
      line-height 86px
    &-t2
      color rgba(255, 255, 255, 0.40)
      font-size 28px
      font-weight 400
      height 32px
      line-height 32px
      margin-top 20px
    &-intro
      margin-top 83px
      font-size 24px
      color rgba(210, 207, 204, 0.42)
      p
        height 28px
        line-height 28px
        margin-bottom 14px
        &:nth-child(1)
          height 31px
          line-height 31px
          font-size 26px
          color rgba(255, 229, 199, 0.8)
          margin-bottom 20px
        &:nth-child(7)
          margin-bottom 0px
    &-btn
      width auto
      height 90px
      margin-top 40px
      display flex
      align-items center
      justify-content center
      span
        width 300px
        height 90px
        display block
        text-align center
        line-height 90px
        border-radius 14px
        background linear-gradient(90deg, #FBD7AF 0%, #F1BE73 100%)
      &-gray
        color rgba(255, 255, 255, 0.20) !important
        background rgba(30, 31, 33, 0.70) !important
    @media screen and (max-width 1200px) and (min-height 1200px)
      width 1040px
      height 687px
      &::after
        width 880px
        height 687px
        background url('https://qncweb.ktvsky.com/20230823/vadd/30b83671cb7aea0bf01a60b25e58e3ef.png') no-repeat
        background-position center
        background-size 100% 100%
      &-p
        padding-left 50px
      &-intro
        margin-top 110px
  .zerobuy-intro
    margin 40px 0
    font-size 28px
    color rgba(255, 255, 255, 0.5)
    @media screen and (max-width 1200px) and (min-height 1200px)
      margin-top 224px
    p
      height 32px
      margin-bottom 16px
      &:nth-child(1)
        margin-bottom 30px
      &:nth-child(7)
        margin-bottom 0px
</style>